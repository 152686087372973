<template>
  <v-btn elevation="2" fab bottom right fixed x-large color="#008d36" href="tel:9981040356">
    <v-icon color="#ffffff" x-large>mdi-phone</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "FloatingPhoneButton"
}
</script>

<style scoped>

</style>