<template>
  <div>
    <ServiceParallax :image="require('@/assets/EnvCons.jpg')" :title="this.$cw.Services.service_1[this.$language.value]"></ServiceParallax>

    <FloatingPhoneButton></FloatingPhoneButton>

    <v-row>
      <v-col class="text-start mt-16 mb-14" offset="1" cols="10">
        <h1 class="dark-green text-center text-md-start my-6 font-weight-bold">{{ this.$cw.Services.service_1[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.EnvCons.text_1[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.EnvCons.text_2[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.EnvCons.text_3[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.EnvCons.text_4[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.EnvCons.text_5[this.$language.value] }}</h1>
        <h1 class="black--text paragraph">{{ this.$cw.EnvCons.text_6[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <ServiceContactNow></ServiceContactNow>
  </div>
</template>

<script>
import ServiceParallax from "./ServiceParallax";
import ServiceContactNow from "./ServiceContactNow";
import FloatingPhoneButton from "./FloatingPhoneButton";
export default {
  name: "EnvConsScreen",
  components: {FloatingPhoneButton, ServiceContactNow, ServiceParallax}
}
</script>

<style scoped>

.dark-green{
  color: #008d36;
}

.paragraph{
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: large;
}

</style>