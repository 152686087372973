import Vue from 'vue';
import Vuetify from 'vuetify';
import styles from "./../styles/styles.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons

Vue.use(Vuetify);
Vue.use(styles);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                text: '#020202',
                primary: '#1345BF',
                accent: '#FF7600',
                white: '#FFFFFF',
                grey: '#E0E0E0',
                darkGrey: '#464646',
                green: '#08B708',
                orange: '#FF7600',
                yellow: '#FFD500',
                aqua: '#01AAB2',
                purple: '#4014C2',
                pink: '#FF0080',
                blue: '#063ABA',
                lightBlue: '#95A9F4',
            }
        }
    },
    icons: {
        iconfont: 'fa',
    },
});