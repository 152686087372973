import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/firebaseConfig'
import '@mdi/font/css/materialdesignicons.css'
import copywrite from './assets/copywrite.json'
import VueSplide from '@splidejs/vue-splide';

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueSplide)

Vue.prototype.$language = Vue.observable({value: 0});
Vue.prototype.$cw = copywrite;

let app;
if(!app) {
  app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}