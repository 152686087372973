import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCMoMjXR70Rsac4bCynlb1Tq_5zGhCJg7k",
    authDomain: "lacandonia-12d35.firebaseapp.com",
    projectId: "lacandonia-12d35",
    storageBucket: "lacandonia-12d35.appspot.com",
    messagingSenderId: "53848244820",
    appId: "1:53848244820:web:08491457a59c44873163c1",
    measurementId: "G-3SZYN0HD7P"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const db = firebase.firestore();



