<template>
    <div class="pa-0">
        <v-row>
            <v-col fluid fill-height class="pa-0" cols="12" id="jumbotron">
                <v-parallax
                    :src="require('@/assets/Home1.jpg')"
                    height="800"
                    class="pa-0"
                >
                    <v-row fluid
                           align="center"
                           justify="center"
                    >
                        <v-col
                            class="text-md-start text-center mt-16 mt-md-0"
                            offset-md="1"
                            cols="12"
                            md="4"
                        >
                            <h1 class="text-md-h3 text-h4 font-weight-bold mb-4 montserrat">
                                {{ this.$cw.Home.company[this.$language.value] }}
                            </h1>
                            <v-btn height="55" width="300" color="#35a336" tile dark class="text-h6"
                                   @click="$vuetify.goTo('#services')"
                            >
                                {{ this.$cw.Home.button_1[this.$language.value] }}
                            </v-btn>
                        </v-col>
                        <v-col cols="0" md="7"></v-col>
                    </v-row>
                </v-parallax>
            </v-col>
        </v-row>

        <FloatingPhoneButton></FloatingPhoneButton>

        <v-row id="services">
            <v-col class="text-center text-md-start mt-16 mb-14" offset-md="1">
                <h1 class="dark-green">{{ this.$cw.Services.title[this.$language.value] }}</h1>
            </v-col>
        </v-row>

        <v-row class="hidden-sm-and-down">
            <ServicesButtonComponent :title="this.$cw.Services.service_1[this.$language.value]" icon="mdi-leaf"
                                     to="/envCons"></ServicesButtonComponent>
            <ServicesButtonComponent :title="this.$cw.Services.service_2[this.$language.value]" icon="mdi-pine-tree"
                                     to="/envLaw"></ServicesButtonComponent>
            <ServicesButtonComponent :title="this.$cw.Services.service_3[this.$language.value]"
                                     icon="mdi-office-building" to="/corporate"></ServicesButtonComponent>
            <ServicesButtonComponent :title="this.$cw.Services.service_4[this.$language.value]" icon="mdi-home"
                                     to="/realState"></ServicesButtonComponent>
            <ServicesButtonComponent :title="this.$cw.Services.service_5[this.$language.value]" icon="mdi-earth"
                                     to="/immigration"></ServicesButtonComponent>
        </v-row>

        <v-row class="hidden-md-and-up px-0">
            <v-col style="background-color: white !important;">
                <ServicesButtonComponent :title="this.$cw.Services.service_1[this.$language.value]" icon="mdi-leaf"
                                         to="/envCons"></ServicesButtonComponent>
                <ServicesButtonComponent :title="this.$cw.Services.service_2[this.$language.value]" icon="mdi-pine-tree"
                                         to="/envLaw"></ServicesButtonComponent>
                <ServicesButtonComponent :title="this.$cw.Services.service_3[this.$language.value]"
                                         icon="mdi-office-building" to="/corporate"></ServicesButtonComponent>
                <ServicesButtonComponent :title="this.$cw.Services.service_4[this.$language.value]" icon="mdi-home"
                                         to="/realState"></ServicesButtonComponent>
                <ServicesButtonComponent :title="this.$cw.Services.service_5[this.$language.value]" icon="mdi-earth"
                                         to="/immigration"></ServicesButtonComponent>
            </v-col>
        </v-row>

        <v-row>
            <v-col offset="1" offset-md="3" cols="10" md="6" class="pt-md-12 pt-8">
                <h1 class="black--text paragraph">{{ this.$cw.Home.comp_desc[this.$language.value] }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn height="55" width="20%" color="#35a336" tile dark
                       class="text-h6 hidden-sm-and-down mt-7" to="/contact">
                    {{ this.$cw.Home.button_2[this.$language.value] }}
                </v-btn>

                <v-btn height="55" width="80%" color="#35a336" tile dark
                       class="text-h6 hidden-md-and-up  mt-7" to="/contact">
                    {{ this.$cw.Home.button_2[this.$language.value] }}
                </v-btn>

            </v-col>
        </v-row>

        <ClientsSection></ClientsSection>

        <v-row>
            <v-col class="text-md-start text-center mt-16 mb-10" offset-md="1">
                <h1 class="dark-green">{{ this.$cw.Philosophy.title[this.$language.value] }}</h1>
            </v-col>
        </v-row>

        <v-row class="mb-13">
            <v-col md="5" offset-md="1" cols="12">
                <v-img :src="require('@/assets/Home2.jpg')" class="invert-image"></v-img>
            </v-col>
            <v-col md="5" cols="12">
                <v-row class="mb-16">
                    <v-col>
                        <h1 class="ml-md-10 mx-8 black--text text-center text-md-start paragraph">
                            {{ this.$cw.Philosophy.desc[this.$language.value] }}</h1>
                    </v-col>
                </v-row>
                <v-row class="my-16 hidden-sm-and-down"></v-row>
                <v-row class="my-16 hidden-sm-and-down"></v-row>
                <v-row class="my-8 hidden-sm-and-down"></v-row>
                <v-row>
                    <v-col offset="1" class="hidden-sm-and-down"></v-col>
                    <v-col class="mt-md-16 mt-0">
                        <v-btn height="55" width="300" color="#1d1d1b" to="/about" tile dark
                               class="text-h6">
                            {{ this.$cw.Philosophy.button[this.$language.value] }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

<!--        <TestimonialsSliderSection></TestimonialsSliderSection>-->

        <ContactScreen></ContactScreen>

    </div>
</template>

<script>
import ServicesButtonComponent from "./ServicesButtonComponent";
import ContactScreen from "./ContactScreen";
import FloatingPhoneButton from "./FloatingPhoneButton";
import ClientsSection from "./ClientsSection";
// import TestimonialsSliderSection from "./TestimonialsSliderSection";

export default {
    name: "HomeScreen",
    components: {
        ClientsSection,
        FloatingPhoneButton,
        ContactScreen,
        ServicesButtonComponent,
        // TestimonialsSliderSection,
    },
    data() {
        return {}
    }
}
</script>

<style scoped>

.dark-green {
    color: #008d36;
}

.paragraph {
    font-family: Roboto, serif;
    font-weight: normal;
    font-size: large;
}

.invert-image {
    transform: rotateY(180deg);
}

.montserrat {
    font-family: Montserrat, serif !important;
}

</style>