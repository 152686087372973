<template>
  <v-row>
    <v-col>
      <v-app-bar fixed>
        <v-row class="fill-height">
          <v-img :src="require('@/assets/LogoNav.png')" max-height="50" max-width="300" contain alt="logoNav"></v-img>
          <v-btn plain x-large color="#1d1d1b" class="navBtn" @click="toEnglish">
            <v-img :src="require('@/assets/usaFlag.jpg')" max-height="50" max-width="50" contain alt="logoNav"></v-img>
            <div>&nbsp;ENG</div>
          </v-btn>

          <v-btn plain x-large color="#1d1d1b" class="navBtn" @click="toSpanish">
            <v-img :src="require('@/assets/mxFlag.png')" max-height="50" max-width="50" contain alt="logoNav"></v-img>
            <div>&nbsp;ESP</div>
          </v-btn>

          <v-spacer></v-spacer>

          <router-link to="/" class="navItem">
            <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Nav.nav_1[this.$language.value] }} </v-btn>
          </router-link>

          <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">

                <v-btn plain x-large color="#008d36" class="navBtn" v-bind="attrs" v-on="on">
                  {{ services }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>

            </template>
            <v-list>
              <v-list-item>
                <router-link to="/envCons" class="navItem">
                  <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Services.service_1[this.$language.value] }}</v-btn>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/envLaw" class="navItem">
                  <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Services.service_2[this.$language.value] }}</v-btn>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/corporate" class="navItem">
                  <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Services.service_3[this.$language.value] }}</v-btn>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/realState" class="navItem">
                  <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Services.service_4[this.$language.value] }}</v-btn>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/immigration" class="navItem">
                  <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Services.service_5[this.$language.value] }}</v-btn>
                </router-link>
              </v-list-item>
            </v-list>

          </v-menu>
          </div>

          <router-link to="/about" class="navItem">
            <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Nav.nav_3[this.$language.value] }}</v-btn>
          </router-link>
          <router-link to="/contact" class="navItem">
            <v-btn plain x-large color="#008d36" class="navBtn">{{ this.$cw.Nav.nav_4[this.$language.value] }}</v-btn>
          </router-link>
        </v-row>
      </v-app-bar>
    </v-col>
  </v-row>
</template>

<script>
export default {
name: "NavBar",
  data(){
    return {
      services: this.$cw.Nav.nav_2[this.$language.value]
    }
  },
  methods: {
    toSpanish() {
      this.$language.value = 1;
      this.services = "SERVICIOS";
    },
    toEnglish(){
      this.$language.value = 0;
      this.services = "SERVICES";
    }
  }
}
</script>

<style scoped>

.navItem{
  text-decoration: none;
  color: inherit;
}

.navBtn {
  letter-spacing: normal;
  font-weight: 700;
  font-size: large !important;
}

</style>