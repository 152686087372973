import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from "../components/HomeScreen";
import EnvConsScreen from "../components/EnvConsScreen";
import EnvLawScreen from "../components/EnvLawScreen";
import CorporateScreen from "../components/CorporateScreen";
import RealStateScreen from "../components/RealStateScreen";
import ImmigrationScreen from "../components/ImmigrationScreen";
import AboutScreen from "../components/AboutScreen";
import ContactScreen from "../components/ContactScreen";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeScreen
  },
  {
    path: '/envCons',
    name: 'Environmental Consulting',
    component: EnvConsScreen
  },
  {
    path: '/envLaw',
    name: 'Environmental Law',
    component: EnvLawScreen
  },
  {
    path: '/corporate',
    name: 'Corporate',
    component: CorporateScreen
  },
  {
    path: '/realState',
    name: 'Real State',
    component: RealStateScreen
  },
  {
    path: '/immigration',
    name: 'Immigration',
    component: ImmigrationScreen
  },
  {
    path: '/about',
    name: 'About',
    component: AboutScreen
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactScreen
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
