<template>
    <v-footer color="#1d1d1b">
        <v-card color="#1d1d1b" width="100%" elevation="0" class="px-md-10 px-4 mt-16 mb-md-5">
            <v-img :src="require('@/assets/logoFooter.png')" max-width="400"></v-img>

            <v-row class="mt-8 mb-10">
                <v-col cols="12" md="3">
                    <h1 class="white--text f-title text-md-start mb-md-3 mb-6">
                        {{ this.$cw.Services.title[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/envCons')">
                        {{ this.$cw.Services.service_1[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/envLaw')">
                        {{ this.$cw.Services.service_2[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/corporate')">
                        {{ this.$cw.Services.service_3[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/realState')">
                        {{ this.$cw.Services.service_4[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-10" @click="redirect('/immigration')">
                        {{ this.$cw.Services.service_5[this.$language.value] }}</h1>
                </v-col>
                <v-col cols="12" md="2">
                    <h1 class="white--text f-title text-md-start mb-md-3 mb-6">GENERAL</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/')">
                        {{ this.$cw.Nav.nav_1[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-3" @click="redirect('/about')">
                        {{ this.$cw.Nav.nav_3[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-10" @click="redirect('/contact')">
                        {{ this.$cw.Nav.nav_4[this.$language.value] }}</h1>
                    <h1 class="white--text f-item text-md-start mb-md-1 mb-10" @click="openPrivacyPolicy">
                        {{ this.$cw.Nav.nav_5[this.$language.value] }}</h1>
                </v-col>
                <v-col cols="12" md="2" offset-md="5">
                    <h1 class="white--text f-title text-md-end mb-3">{{
                            this.$cw.Contact.title[this.$language.value]
                        }}</h1>
                    <h2 class="white--text f-item text-md-end mb-10" @click="mailClick">{{ this.$cw.Contact.mail }}</h2>
                    <v-row class="justify-md-end justify-center">
                        <v-icon size="40" class="white--text mx-1" @click="phoneClick">mdi-phone</v-icon>
                        <v-icon size="40" class="white--text mx-1" @click="locationClick">mdi-map-marker</v-icon>
                        <v-icon size="40" class="white--text mx-1" @click="mailClick">mdi-email</v-icon>
                        <v-icon size="40" class="white--text mx-1" @click="linkedinClick">mdi-linkedin</v-icon>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="divider mb-4" dark></v-divider>
            <v-row>
                <v-col cols="12" md="6">
<!--                    <h1 class="white&#45;&#45;text f-title text-md-start">Consultoría Especializada Lacandonia S.C.</h1>-->
                </v-col>
                <v-col cols="12" md="6">
                    <h1 class="white--text f-title text-md-end">Developed by <a href="https://ideen.mx" target="_blank">ideen</a>. México, 2021.</h1>
                </v-col>
            </v-row>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        redirect(route) {
            window.location.href = route;
        },
        locationClick() {
            window.open('https://www.google.com/maps/place/Centralia+Business+Park+and+Plaza/@21.04593,-86.849703,16z/data=!4m5!3m4!1s0x0:0x8f6e7379237cdc0b!8m2!3d21.0459298!4d-86.8497031?hl=es-419');
        },
        phoneClick() {
            window.location.href = 'tel:9981040356';
        },
        mailClick() {
            window.location.href = 'mailto:atencionalcliente@lacandonia.com.mx';
        },
        linkedinClick() {
            window.open('https://www.linkedin.com/company/lacandonia');
        },
        openPrivacyPolicy() {
            // spanish
            let spanishUrl = "https://drive.google.com/file/d/1X7TR_PcHYmzZQ8mlQDmf0RgVY_UFz_q9/view?usp=sharing"

            // english
            let englishUrl = "https://drive.google.com/file/d/1IgNPSFNyJ5ZYNsyc9fdOQ-lMAxnDq_5M/view?usp=sharing"

            window.open(this.$language.value === 0 ? englishUrl : spanishUrl);
        }
    }
}
</script>

<style scoped>

.f-title {
    font-family: Roboto, serif;
    font-weight: bold;
    font-size: large;
}

.f-item {
    font-family: Roboto, serif;
    font-weight: 400;
    font-size: 0.75em;
}

.divider {
    background-color: #ffffff;
}

</style>