<template>

  <v-row class="mt-10">
    <v-col offset-md="4" offset="1" cols="10" md="4">
      <v-img :src="require('@/assets/logoFull.png')"></v-img>
      <v-btn height="55" width="80%" color="#35a336" tile dark class="text-h6 my-16" to="/contact">
        {{ this.$cw.Home.button_2[this.$language.value] }}
      </v-btn>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "ServiceContactNow"
}
</script>

<style scoped>

</style>