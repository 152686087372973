<template>
  <v-app>
    <div id="app">
      <NavBar class="hidden-sm-and-down"></NavBar>
      <NavBarMobile class="hidden-md-and-up"></NavBarMobile>
      <router-view></router-view>
      <Footer></Footer>
    </div>
  </v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#app {
  font-family: 'Montserrat', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.splide__pagination__page.is-active {
    background: #008d36 !important;
}

.splide__pagination__page {
    background: #2c3e50 !important;
}

</style>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import NavBarMobile from "./components/NavBarMobile";
export default {
  components: {NavBarMobile, Footer, NavBar},

}
</script>
