<template>
  <v-col class="pa-0">
    <v-card tile elevation="0" color="#1d1d1b" :to="to" class="dark-green hidden-sm-and-down align-content-center hover-effect" height="300">
      <div class="pt-13">
        <h1 class="text-h5 font-weight-bold px-16">{{ title }}</h1>
        <v-icon dark size="90" class="parent-color pt-9">{{ icon }}</v-icon>
      </div>
    </v-card>

    <v-card tile elevation="0" color="#1d1d1b" :to="to" class="mb-5 dark-green hidden-md-and-up hover-effect" width="100%" height="140">
      <v-row class="pt-6 pr-4">
        <v-col cols="3" class="pl-7 pt-5">
          <v-icon dark size="45" class="parent-color">{{ icon }}</v-icon>
        </v-col>
        <v-col cols="7" class="px-0">
          <h1 class="text-h5 text-start font-weight-bold pr-5">{{ title }}</h1>
        </v-col>
        <v-col cols="2" class="px-0 text-start pt-5">
          <v-icon dark size="45" class="parent-color">mdi-arrow-right</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "ServicesButtonComponent",
  props: ['title', 'icon', 'to']
}
</script>

<style scoped>
.hover-effect:hover{
  background-color: #35a336 !important;
  color: white !important;
}

.dark-green{
  color: #008d36 !important;
}

.parent-color{
  color: inherit;
}
</style>