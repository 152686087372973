<template>
  <v-row>
    <v-col class="pt-0">

      <v-app-bar fixed>
        <v-row class="fill-height pt-1">
          <v-img :src="require('@/assets/LogoNav.png')" max-height="40" max-width="220" contain alt="logoNav"></v-img>

          <v-spacer></v-spacer>

          <v-btn icon class="pb-2 mr-4" @click="drawer = true">
            <v-icon class="normal-green" size="30">mdi-menu</v-icon>
          </v-btn>
        </v-row>
      </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          app
          temporary
          width="100%"
          color="#35a336"
      >

        <v-list
            nav
            dense
        >
          <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-row class="mt-2 mr-2 mb-16">
                <v-img :src="require('@/assets/logoWhite.png')" max-height="40" max-width="220" contain alt="logoNav"></v-img>

                <v-spacer></v-spacer>

                <v-btn icon class="" @click="drawer = false">
                  <v-icon class="white--text" size="30">mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-list-item>

            <router-link to="/" class="navItem">
              <v-list-item class="mt-16">
                <v-list-item-title class="navBtn">
                    {{ this.$cw.Nav.nav_1[this.$language.value] }}
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{on, attrs}">

                  <v-list-item class="">
                    <v-list-item-title class="navBtn" v-bind="attrs" v-on="on">
                      {{ services }} <v-icon class="white--text">mdi-menu-down</v-icon>
                    </v-list-item-title>
                  </v-list-item>

                </template>
                <v-list>
                  <v-list-item class="justify-center">
                    <router-link to="/envCons" class="navItem services">
                      {{ this.$cw.Services.service_1[this.$language.value] }}
                    </router-link>
                  </v-list-item>
                  <v-list-item class="justify-center">
                    <router-link to="/envLaw" class="navItem services">
                      {{ this.$cw.Services.service_2[this.$language.value] }}
                    </router-link>
                  </v-list-item>
                  <v-list-item class="justify-center">
                    <router-link to="/corporate" class="navItem services">
                      {{ this.$cw.Services.service_3[this.$language.value] }}
                    </router-link>
                  </v-list-item>
                  <v-list-item class="justify-center">
                    <router-link to="/realState" class="navItem services">
                      {{ this.$cw.Services.service_4[this.$language.value] }}
                    </router-link>
                  </v-list-item>
                  <v-list-item class="justify-center">
                    <router-link to="/immigration" class="navItem services">
                      {{ this.$cw.Services.service_5[this.$language.value] }}
                    </router-link>
                  </v-list-item>
                </v-list>

              </v-menu>
            </div>

            <router-link to="/about" class="navItem">
              <v-list-item>
                <v-list-item-title class="navBtn">
                  {{ this.$cw.Nav.nav_3[this.$language.value] }}
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link to="/contact" class="navItem">
              <v-list-item class="mb-16">
                <v-list-item-title class="navBtn">
                  {{ this.$cw.Nav.nav_4[this.$language.value] }}
                </v-list-item-title>
              </v-list-item>
            </router-link>

          </v-list-item-group>
        </v-list>

        <v-btn plain x-large color="#1d1d1b" class="navBtn mt-16" @click="toEnglish">
          <v-img :src="require('@/assets/usaFlag.jpg')" max-height="50" max-width="50" contain alt="logoNav"></v-img>
          <div>&nbsp;ENG</div>
        </v-btn>

        <v-btn plain x-large color="#1d1d1b" class="navBtn mt-16" @click="toSpanish">
          <v-img :src="require('@/assets/mxFlag.png')" max-height="50" max-width="50" contain alt="logoNav"></v-img>
          <div>&nbsp;ESP</div>
        </v-btn>

      </v-navigation-drawer>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NavBarMobile",
  data(){
    return {
      drawer: false,
      group: null,
      services: this.$cw.Nav.nav_2[this.$language.value],
    }
  },
  methods: {
    toSpanish() {
      this.$language.value = 1;
      this.services = "SERVICIOS";
    },
    toEnglish(){
      this.$language.value = 0;
      this.services = "SERVICES";
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>

<style scoped>

.normal-green {
  color: #35a336 !important;
}

.navItem{
  text-decoration: none;
}

.navBtn {
  color: white !important;
  letter-spacing: normal;
  font-weight: bolder;
  font-size: large !important;
}

.services {
  color: #35a336 !important;
  letter-spacing: normal;
  font-weight: bolder;
  font-size: medium !important;
}

</style>