<template>

  <div>
    <v-row>
      <v-col class="text-start mb-10 align-center">
        <h1 class="normal-green text-center mt-16">{{ this.$cw.Contact.title[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <v-row class="mb-16" no-gutters>
      <v-col cols="12" md="6" class="px-md-10">
        <v-icon size="50" class="normal-green pb-5" @click="locationClick">mdi-map-marker</v-icon>
        <h1 class="black--text paragraph" @click="locationClick">{{ this.$cw.Contact.location_1[this.$language.value] }}</h1>
        <h1 class="black--text paragraph px-md-16 px-6" @click="locationClick">{{ this.$cw.Contact.location_2[this.$language.value] }}</h1>
        <h1 class="black--text paragraph" @click="locationClick">{{ this.$cw.Contact.location_3[this.$language.value] }}</h1>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.668909197095!2d-86.85189178457827!3d21.045929785989063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e81c6628c583d%3A0x8f6e7379237cdc0b!2sCentralia%20Business%20Park%20and%20Plaza!5e0!3m2!1ses-419!2smx!4v1634052572393!5m2!1ses-419!2smx"
                width="100%"
                height="300"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5"
        ></iframe>

        <v-row class="mt-5">
          <v-col cols="12" md="4" @click="phoneClick">
            <v-icon size="50" class="normal-green mb-3">mdi-phone</v-icon>
            <h1 class="black--text paragraph mb-5 mb-md-0">{{ this.$cw.Contact.phone }}</h1>
          </v-col>
          <v-col cols="12" md="4" @click="mailClick">
            <v-icon size="50" class="normal-green mb-3">mdi-email</v-icon>
            <h1 class="black--text paragraph mb-5 mb-md-0">{{ this.$cw.Contact.mail }}</h1>
          </v-col>
          <v-col cols="12" md="4" @click="whatsClick">
            <v-icon size="50" class="normal-green mb-3">mdi-whatsapp</v-icon>
            <h1 class="black--text paragraph mb-5 mb-md-0">{{ this.$cw.Contact.whats }}</h1>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="12" md="6" class="px-md-10 px-4">
        <v-card outlined tile class="card-style pa-8">
          <v-form ref="form" >

            <h1 class="normal-green text-start text-h6 form-label">{{ this.$cw.Contact.form.name[this.$language.value] }}</h1>
            <v-text-field
                outlined
                v-model="name"
                :rules="requiredRule"
                required
                color="#35a336"
            ></v-text-field>

            <h1 class="normal-green text-start text-h6 form-label">{{ this.$cw.Contact.form.mail[this.$language.value] }}</h1>
            <v-text-field
                outlined
                v-model="email"
                :rules="requiredRule"
                required
                color="#35a336"
            ></v-text-field>

            <h1 class="normal-green text-start text-h6 form-label">{{ this.$cw.Contact.form.tel[this.$language.value] }}</h1>
            <v-text-field
                outlined
                v-model="tel"
                :rules="requiredRule"
                required
                color="#35a336"
            ></v-text-field>

            <h1 class="normal-green text-start text-h6 form-label">{{ this.$cw.Contact.form.mssg[this.$language.value] }}</h1>
            <v-textarea
                outlined
                solo
                v-model="mssg"
                :rules="requiredRule"
                color="#35a336"
                no-resize
            ></v-textarea>

            <v-btn height="55" width="300" color="#63b239" tile dark class="text-h6 hidden-sm-and-down" @click="send">
              {{ this.$cw.Contact.form.button[this.$language.value] }}
            </v-btn>

            <v-btn height="55" width="100%" color="#63b239" tile dark class="text-h6 hidden-md-and-up" @click="send">
              {{ this.$cw.Contact.form.button[this.$language.value] }}
            </v-btn>

          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "ContactScreen",
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      mssg: '',
      requiredRule: [
        v => !!v || "This field is required.",
      ],
    }
  },
  methods: {
    async send(){
      if(this.$refs.form.validate()){
          let body = {
              "name": this.name,
              "email": this.email,
              "phone": this.tel,
              "msg": this.msg,
              "sendTo": "benjamin@ideen.mx"
          }

          await axios.post('https://us-central1-ideenwebsite.cloudfunctions.net/sendContactFormToSales', body);
      }
    },
    locationClick(){
      window.open('https://www.google.com/maps/place/Centralia+Business+Park+and+Plaza/@21.04593,-86.849703,16z/data=!4m5!3m4!1s0x0:0x8f6e7379237cdc0b!8m2!3d21.0459298!4d-86.8497031?hl=es-419');
    },
    phoneClick(){
      window.location.href = 'tel:9984150851';
    },
    mailClick(){
      window.location.href = 'mailto:atencionalcliente@lacandonia.com.mx';
    },
    whatsClick(){
      window.location.href = 'tel:9981040356';
    },
  }
}
</script>

<style scoped>

.normal-green {
  color: #35a336;
}

.v-text-field--outlined >>> fieldset {
  border: 2px solid #35a336;
  border-radius: 0;
}

.form-label {
  font-family: Montserrat, serif !important;
  font-size: medium !important;
  font-weight: bold;
}

.light-green {
  color: #63b239;
}

.card-style {
  border: 2px solid #35a336;
}

.paragraph{
  font-family: Roboto, serif;
  font-weight: bold;
  font-size: 0.75em;
}

</style>b