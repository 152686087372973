<template>
  <div>
    <v-row>
      <v-col class="text-md-start text-center mt-16 mb-md-14 mb-7" offset-md="1">
        <h1 class="dark-grey">{{ this.$cw.Clients.title[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col offset-md="3" md="2" offset="1" cols="5">
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_11[this.$language.value]}}</h1>
        <h1 class="data-number">1</h1>
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_12[this.$language.value]}}</h1>
      </v-col>
      <v-col md="2" cols="5">
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_21[this.$language.value]}}</h1>
        <h1 class="data-number">500+</h1>
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_22[this.$language.value]}}</h1>
      </v-col>
      <v-col md="2" cols="12">
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_31[this.$language.value]}}</h1>
        <h1 class="data-number">350+</h1>
        <h1 class="data-description">{{ this.$cw.Clients.data_desc_32[this.$language.value]}}</h1>
      </v-col>
    </v-row>

    <v-row class="my-10 mx-md-0 mx-2">
      <v-col offset="0" offset-md="2" cols="0" class="hidden-sm-and-down"></v-col>
      <v-col md="1" cols="4" class="mx-md-6 mb-md-5">
        <v-img :src="require('@/assets/client1.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client2.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client3.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client4.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client5.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col offset="2" class="hidden-sm-and-down"></v-col>
      <v-col offset="0" offset-md="2" cols="0" class="hidden-sm-and-down"></v-col>
      <v-col md="1" cols="4" class="mx-md-6 mb-md-5">
        <v-img :src="require('@/assets/client6.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client7.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client8.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client9.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client10.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col offset="2" class="hidden-sm-and-down"></v-col>
      <v-col offset="2" class="hidden-sm-and-down"></v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client11.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client12.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client13.png')" max-width="100" max-height="100"></v-img>
      </v-col>
      <v-col md="1" cols="4" class="mx-md-6">
        <v-img :src="require('@/assets/client14.png')" max-width="100" max-height="100"></v-img>
      </v-col>

      <v-col offset="0" offset-md="2"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ClientsSection"
}
</script>

<style scoped>

.dark-grey{
  color: #1d1d1b;
}

.data-number{
  color: #008d36;
  font-family: Montserrat, serif;
  font-weight: bold;
  font-size: 70px;
}

.data-description{
  color: #008d36;
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: x-large;
}

@media only screen and (max-width: 640px) {
    .data-number {
        font-size: 50px;
    }

    .data-description{
        font-size: medium;
    }
}

</style>