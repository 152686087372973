<template>
  <v-parallax
      dark
      :src="this.image"
      height="800"
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          class="text-center"
          cols="12"
      >
        <h1 class="text-h3 font-weight-bold mb-4 montserrat">
          {{ title }}
        </h1>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "ServiceParallax",
  props: ['image', 'title']
}
</script>

<style scoped>

.montserrat {
  font-family: Montserrat, serif !important;
}

</style>