<template>
  <div>
    <ServiceParallax :image="require('@/assets/AboutUs.jpg')" :title="this.$cw.Nav.nav_3[this.$language.value]"></ServiceParallax>
    <FloatingPhoneButton></FloatingPhoneButton>

    <v-row>
      <v-col class="text-start mt-16 mb-14" offset="1" cols="10">
        <h1 class="dark-green text-center text-md-start my-6 font-weight-bold">{{ this.$cw.AboutUs.title[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.AboutUs.text_1[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.AboutUs.text_2[this.$language.value] }}</h1>
        <h1 class="black--text paragraph">{{ this.$cw.AboutUs.text_3[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <ClientsSection></ClientsSection>

    <v-row>
      <v-col class="text-start mt-16 mb-14" offset="1" cols="10">
        <h1 class="dark-green text-center text-md-start my-6 font-weight-bold">{{ this.$cw.AboutUs.mission[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.AboutUs.mission_text[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-start mt-16 mb-14" offset="1" cols="10">
        <h1 class="normal-green text-center text-md-start my-6 font-weight-bold">{{ this.$cw.AboutUs.vision[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.AboutUs.vision_text[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-start mt-16 mb-14" offset="1" cols="10">
        <h1 class="lighter-green text-center text-md-start my-6 font-weight-bold">{{ this.$cw.AboutUs.values[this.$language.value] }}</h1>
        <h1 class="black--text paragraph mb-4">{{ this.$cw.AboutUs.values_text[this.$language.value] }}</h1>
      </v-col>
    </v-row>

    <ServiceContactNow></ServiceContactNow>
  </div>
</template>

<script>
import ServiceParallax from "./ServiceParallax";
import FloatingPhoneButton from "./FloatingPhoneButton";
import ClientsSection from "./ClientsSection";
import ServiceContactNow from "./ServiceContactNow";

export default {
  name: "AboutScreen",
  components: {ServiceContactNow, ClientsSection, ServiceParallax, FloatingPhoneButton}
}
</script>

<style scoped>

.dark-green{
  color: #008d36;
}

.normal-green {
  color: #35a336;
}

.lighter-green {
  color: #63b239;
}

.paragraph{
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: large;
}

</style>